import React from 'react';
import { Container, Navbar, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../img/app-icon-without-background.png';
import { LoyaltyOffersContext } from '../LoyaltyOffersContext';
import { useContext } from 'react';
import { logout, roleAdministrator, roleAgent, userRoleHeader } from './Library'
import { BusinessSelector } from './BusinessSelector';

export function NavMenu() {

    const { hasOffers } = useContext(LoyaltyOffersContext);
    let additionalMatchesLink;
    let addBusinessLink;

    if (hasOffers) {
        additionalMatchesLink = <NavItem>
            <NavLink tag={Link} className="text-dark" to="/">Transfer Points from Existing Cards</NavLink>
        </NavItem>
    }

    let userRole = JSON.parse(sessionStorage.getItem(userRoleHeader));

    if (userRole == roleAdministrator || userRole == roleAgent) {
        addBusinessLink = <NavItem>
            <NavLink tag={Link} className="text-dark" to="/business">Add New Business</NavLink></NavItem>
    }

    return (
        <header>
        <BusinessSelector/>
            <Navbar className="navbar" light>
                <Container>
                    <img className="logo" src={logo} alt="Receipts and Rewards Logo" width="58px" height='58px' />
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/loyaltyoffer">Loyalty Offer</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/message">Message</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/voucher">Voucher</NavLink>
                        </NavItem>
                        {additionalMatchesLink}
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/reviewrequesttemplate">Request Reviews</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/receiptsforbusiness">Receipt History</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/tills">QR Codes for Tills</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/loyaltyoffers">QR Codes for Loyalty Offers</NavLink>
                        </NavItem>
                        {addBusinessLink }
                    </ul>

                    <button onClick={logout} className='form-input-button'>Logoff</button>
                </Container>
            </Navbar>
        </header>
    );
}
