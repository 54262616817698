import React, { Component, useContext } from 'react'
import Select from 'react-select'
import { isLoggedIn, fetchSignedMessage, getSessionValue, businessIdHeader, postMethod, getMethod, httpOKsuccess, cssFormInputLabel, cssFormInputNumber, cssFormInputText, cssFormInputFile, cssFormInputButton, cssFormInput, postAttachment, cssFormInputDropdown, cssError, fileFieldType, numberField, cssFormCheckBox, checkBox } from './Library'
import CurrencyInput from 'react-currency-input-field'
import ShowHelp from '../img/help-icon.png'
import HideHelp from '../img/help-close-icon.png'
import { LoyaltyOffersContext, LoyaltyOffersProvider } from '../LoyaltyOffersContext'
import { NavMenu } from '../components/NavMenu';
import ReactDOM from 'react-dom'
import DualBallSpinner from '../img/dual-ball-1s-100px.gif'
import { validate } from 'C:/Users/vince/AppData/Local/Microsoft/TypeScript/5.4/node_modules/@babel/types/lib/index'


const targetSpend = "sp";

const rewardPercentage = "rp";
const rewardNominal = "rn";

const loyaltyOfferTargets = [{ key: targetSpend, value: targetSpend, label: 'Spend Requirement' }];
const loyaltyOfferRewards = [{ key: rewardNominal, value: rewardNominal, label: 'Amount off next bill' }, {
	key: rewardPercentage, value: rewardPercentage, label: 'Percentage off next bill'
}];

const loyaltyOfferPeriodRewardClassName = "LoyaltyOfferExpiryPeriod";

export class LoyaltyOfferRulesOption {
	constructor(key, text) {
		this.label = text;
		this.value = key;
	}
}
export class LoyaltyOffer extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loyaltyOfferTarget: '',
			loyaltyOfferDiscount: '',
			businessId: '',
			signUpAlertText: '',
			loyaltyOfferStandardRuleId: null,
			loyaltyOfferRuleId: null,
			loyaltyOfferLifetimeId: null,
			loyaltyOfferPeriodRewardId: null,
			numberOfPoints: null,
			offerDetails: '',
			displayNextSteppedOfferDescription: false,
			percentageOff: null,
			spendRequirement: null,
			loyaltyOfferRuless: [],
			steppedOfferTiers: [],
			alertTitle: '',
			alertBody: '',
			messageTitle: '',
			messageBody: '',
			attachmentPath: '',
			attachment: null,
			messageLink: '',
			waitForMessageMonths: null,
			waitForMessageDays: null,
			waitForMessageHours: null,
			waitForMessageMinutes: null,
			years: null,
			months: null,
			days: null,
			loading: true,
			errorNumberOfPoints: '',
			errorPercentageOff: '',
			errorSpendRequirement: '',
			errorOfferDetails: '',
			errorLoyaltyOfferId: '',
			errorTimePeriod: '',
			helpVisible: false,
			submitButtonDisabled: false
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}



	async componentDidMount() {
		this.getLoyaltyOfferRulesData();
		await isLoggedIn();
		this.setState({ loading: false })
	}


	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.type === numberField ? parseInt(target.value) : target.value;
		const name = target.name;

		this.setState({
			[name]: value
	});
	}

	handleCurrencyChange = (value) => {

		this.setState({
			spendRequirement: value
		});
    }

	handleDropDownChange = (e, data) => {
		const name = data.name;
		this.setState({
			[name]: e.value
		})
	}

	handleFileChange = (event) => {
		this.setState({
			attachment: event.target.files[0]
		})
	}

	handleSubmit = (event) => {
		let isValid = this.handleValidation();

		for (let i = 0; i < this.state.steppedOfferTiers.length; i++) {
			if (!this.handleSteppedOfferValidation(this.state.steppedOfferTiers[i])) {
				isValid = false;
			}
		}

		event.preventDefault();
		var apiMethod = "loyaltyoffer";


		if (isValid) {
			var responseLabel = document.getElementById("response");
			ReactDOM.render('', responseLabel);
			this.setState({ loading: true, submitButtonDisabled: true });

			var loyaltyOffer = {
				signUpAlertText: this.state.signUpAlertText,
				businessId: getSessionValue(businessIdHeader),
				loyaltyOfferRuleId: this.state.loyaltyOfferRuleId,
				offerDetails: this.state.offerDetails,
				requiredNumberOfMatches: this.state.numberOfPoints,
				displayNextSteppedOfferDescription: this.state.displayNextSteppedOfferDescription
			};

			if (this.state.loyaltyOfferRuleId == this.state.loyaltyOfferPeriodRewardId) {
				loyaltyOffer.years = this.state.years;
				loyaltyOffer.months = this.state.months;
				loyaltyOffer.days = this.state.days;

				var apiMethod = loyaltyOfferPeriodRewardClassName.toLowerCase();
			}

			if (this.state.attachment != null) {
				this.state.attachmentPath = postAttachment(this.state.attachment);
				messageData.attachmentPath = 'https://' + process.env.REACT_APP_MESSAGE_BUCKET_NAME + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + this.state.attachmentPath;
			}

			switch (this.state.loyaltyOfferTarget) {
				case targetSpend:
					var loyaltyOfferSpendRequirement = {
						spendRequirement: this.state.spendRequirement
					};
					loyaltyOffer.target = loyaltyOfferSpendRequirement;
					break;
			}

			switch (this.state.loyaltyOfferDiscount) {
				case rewardPercentage:
					var loyaltyDiscountPercentageReward = {
						percentageOff: this.state.percentageOff
					};
					loyaltyOffer.reward = loyaltyDiscountPercentageReward;
					break;
				case rewardNominal:
					var loyaltyDiscountNominalAmountReward = {
						discount: this.state.nominalAmountOff
					};
					loyaltyOffer.reward = loyaltyDiscountNominalAmountReward;
					break;
			}

			if (this.state.steppedOfferTiers.length > 0) {
				loyaltyOffer.steppedRewards = [];
			}

			for (let i = 0; i < this.state.steppedOfferTiers.length; i++) {
				const offerTier = this.state.steppedOfferTiers[i];
				var loyaltySteppedOfferTier = {
					numberOfStamps: offerTier.numberOfStamps
				}

				if (offerTier.icon != null) {
					offerTier.iconPath = postAttachment(offerTier.icon);
					loyaltySteppedOfferTier.iconPath = 'https://' + process.env.REACT_APP_MESSAGE_BUCKET_NAME + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + offerTier.iconPath;
				}

				if (offerTier.redeemedIcon != null) {
					offerTier.redeemedIconPath = postAttachment(offerTier.redeemedIcon);
					loyaltySteppedOfferTier.redeemedIconPath = 'https://' + process.env.REACT_APP_MESSAGE_BUCKET_NAME + '.s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + offerTier.iconPath
				}

				switch (offerTier.loyaltyOfferDiscount) {
					case rewardPercentage:
						var loyaltyDiscountPercentageReward = {
							percentageOff: offerTier.percentageOff
						};
						loyaltySteppedOfferTier.reward = loyaltyDiscountPercentageReward;
						break;
					case rewardNominal:
						var loyaltyDiscountNominalAmountReward = {
							discount: offerTier.nominalAmountOff
						}
						loyaltySteppedOfferTier.reward = loyaltyDiscountNominalAmountReward;
				}
				loyaltySteppedOfferTier.tierDetails = offerTier.tierDetails;
				loyaltyOffer.steppedRewards.push(loyaltySteppedOfferTier);
			}

			fetchSignedMessage(JSON.stringify(loyaltyOffer), apiMethod, postMethod).then(response => {
				if (response.status == httpOKsuccess) {
					ReactDOM.render(<label className={cssFormInputLabel}>Loyalty Offer Saved.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				} else {
					ReactDOM.render(<label className={cssFormInputLabel}>Loyalty Offer saving failed. Please try again later.</label>, responseLabel);
					this.setState({ loading: false, sumbitButtonDisabled: false });
				}
			}).catch(error => {
				this.setState({ loading: false, sumbitButtonDisabled: false });
				ReactDOM.render(<label className={cssFormInputLabel}>Loyalty Offer saving failed. Please try again later.</label>, responseLabel);
				this.setState({ loading: false, submitButtonDisabled: false });
			});

			const { addLoyaltyOffer } = useContext(LoyaltyOffersContext);
			addLoyaltyOffer.addLoyaltyOffer(loyaltyOffer);


		}
	}

	handleAddSteppedOfferTier = (event) => {

		this.setState({
			steppedOfferTiers: this.state.steppedOfferTiers.concat(
				[{ numberOfStamps: 0, percentageOff: 0.00, errorNumberOfStamps: '', errorTierDetails: '' }]
			)
		})

	}

	handleSteppedOfferChange = (elementIndex) => (event) => {

		let steppedOfferTiers = this.state.steppedOfferTiers.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, [event.target.name]: event.target.type === numberField ? Number(event.target.value) : event.target.value }
		})

		this.setState({steppedOfferTiers})
	}

	handleSteppedFileChange = (elementIndex) => (event) => {

		let steppedOfferTiers = this.state.steppedOfferTiers.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, [event.target.name]: event.target.files[0]}
		})

		this.setState({steppedOfferTiers})
	}

	handleRemoveSteppedOffer = (elementIndex) => (event) => {
		this.setState({
			steppedOfferTiers: this.state.steppedOfferTiers.filter((item, i) => {
				return elementIndex != i
            })
        })
	}

	handleSteppedDropDownChange = (elementIndex) => (event) => {
		let steppedOfferTiers = this.state.steppedOfferTiers.map((item, i) => {
			if (elementIndex !== i) return item
			return { ...item, 'loyaltyOfferDiscount': event.key }
		})

		this.setState({ steppedOfferTiers })
	}

	handleSteppedOfferValidation(item) {
		let validated = true;

		if (item.numberOfStamps == null || item.numberOfStamps <= 0) {
			validated = false;
			item.errorNumberOfStamps = "Number of stamps should be greater than zero"
		} else {
			item.errorNumberOfStamps = "";
		}
		
		if (item.tierDetails == null || item.tierDetails.length == 0) {
			validated = false;
			item.errorTierDetails = "You must provide a description for the tier";
		} else {
			item.errorTierDetails = "";
		}
	}

	handleFocusSelect = (event) => {
		event.target.select()
	}

	async getLoyaltyOfferRulesData() {
		try {
			const response = await fetchSignedMessage(null, 'loyaltyofferrule', getMethod);
			const data = await response.json();
			this.setState({ loyaltyOfferRuless: data.map((loyaltyOfferRules) => new LoyaltyOfferRulesOption(loyaltyOfferRules.loyaltyOfferRuleId, loyaltyOfferRules.loyaltyOfferRuleName)), loading: false });

			for (var i = 0; i < data.length; i++) {
				var loyaltyOfferRule = data[i];
				if (loyaltyOfferRule.className == 'LoyaltyOffer') {
					this.setState({
						['loyaltyOfferRuleId']: loyaltyOfferRule.loyaltyOfferRuleId
					})
				} else if (loyaltyOfferRule.className == 'LoyaltyOfferLifetimeReward') {
					this.setState({
						['loyaltyOfferLifetimeId']: loyaltyOfferRule.loyaltyOfferRuleId
					});
				} else if (loyaltyOfferRule.className == loyaltyOfferPeriodRewardClassName) {
					this.setState({
						['loyaltyOfferPeriodRewardId']: loyaltyOfferRule.loyaltyOfferRuleId
					});
				}
			}
		}
		catch (error) {
			console.log(error);
			const networkErrorEmail = "mailto:tech@receiptsandrewards.com?subject=Login%20-%20Network%20Error&body=A%20User%20is%20unable%20to%20logon%20to%20" + window.location.host;
			var messageLabel = document.getElementById("response");
			ReactDOM.render(<label className={cssFormInputLabel} >There appears to be a network error. Please <a href={networkErrorEmail}>contact Receipts and Rewards</a> and we will fix it.</label>, messageLabel);
			this.setState({ loading: false, buttonDisabled: false });
		}
	}

	DisplayLoyaltyOfferRulesDropdown() {
		const { value } = this.state;
		if (this.state.loyaltyOfferRuless.length == 1) {
			this.state.loyaltyOfferRuleId = this.state.loyaltyOfferRuless[0].value;
			return <div><b>{this.state.loyaltyOfferRuless[0].label}</b></div>;
		}
		else {
			return (<Select
				className={cssFormInputDropdown}
				placeholder='Select Loyalty Offer Rule'
				fluid
				selection
				search
				options={this.state.loyaltyOfferRuless}
				name="loyaltyOfferRuleId"
				onChange={this.handleDropDownChange}
				value={value}
			/>
			);
		}
	}

	handleValidation() {

		let validated = true;

		if (this.state.offerDetails.length == 0) {
			this.setState({ errorSignUpAlertText: "You must include the offer details" });
			validated = false;
		} else {
			this.setState({ errorSignUpAlertText: "" });
		}

		if (this.state.loyaltyOfferRuleId == null) {
			this.setState({ errorLoyaltyOfferId: "You must select a loyalty offer rule" });
			validated = false;
		} else {
			this.setState({ errorLoyaltyOfferId: "" });
		}

		if (this.state.numberOfPoints == null || this.state.numberOfPoints <= 0) {
			this.setState({ errorNumberOfPoints: "You must select the number of points to get the reward" });
			validated = false;
		} else {
			this.setState({ errorNumberOfPoints: "" });
		}

		if (this.state.loyaltyOfferRuleId == this.state.spendAmountForPointOfferId) {
			if (!this.validateNumericNumberIsGreaterThanZero(this.state.spendRequirement, "errorSpendRequirement", "You must have a spend requirement greater than zero")) {
				validated = false;
			}

			if (!this.validateNumericNumberIsGreaterThanZero(this.state.numberOfPoints, "errorNumberOfPoints", "You must have a number of points greater than zero")) {
				validated = false;
			}

			if (!this.validateNumericNumberIsGreaterThanZero(this.state.percentageOff, "errorPercentageOff", "You must have a percentage discount greater than zero")) {
				validated = false;
            }
		}

		if (this.state.loyaltyOfferRuleId == this.state.loyaltyOfferPeriodRewardId) {
			if ((this.state.years ?? 0 + this.state.months ?? 0 + this.state.days ?? 0) == 0) {
				this.setState({ errorTimePeriod: "You must set a time period"});
				validated == false;
			} else {
				this.setState({errorTimePeriod: "" });
			}
		}

		return validated;
	}

	validateNumericNumberIsGreaterThanZero(valueToValidate, stateForErrorMessage, errorMessage) {

	if (valueToValidate == null || valueToValidate <= 0) {
		this.setState({ [stateForErrorMessage]: errorMessage });
		return false;
	} else {
		this.setState({ [stateForErrorMessage]: "" });
		return true;
	}
}

	toggleHelp = (event) => {
		this.setState({
			helpVisible: !this.state.helpVisible
		});
	}

	loyaltyOfferTargetDescription() {
		switch (this.state.loyaltyOfferTarget) {
			case targetSpend:
				return <><label className={cssFormInputLabel}>
					Spend Amount for a Point:
					<CurrencyInput name="spendRequirement" className={cssFormInputNumber} allowDecimals="true" allowNegativeValue="false" decimalsLimit="2" decimalScale="2" intlConfig={{ locale: 'en-US', currency: 'GBP' }} value={this.state.spendRequirement} onValueChange={this.handleCurrencyChange} />
				</label>{this.state.errorSpendRequirement && <p className={cssError} >{this.state.errorSpendRequirement}</p>}</>
		}
	}

	loyaltyOfferDiscount() {
		switch (this.state.loyaltyOfferDiscount) { 
			case rewardPercentage:
				return <><label className={cssFormInputLabel}>
					Percentage Off When Points Earned:
					<input className={cssFormInputNumber} type={numberField} name="percentageOff" value={this.state.percentageOff} onChange={this.handleInputChange} />
				</label>{this.state.errorPercentageOff && <p className={cssError } >{this.state.errorPercentageOff}</p>}</>
			case rewardNominal:
				return <><label className={cssFormInputLabel}>
					Discount Amount When Points Earned:
					<input className={cssFormInputNumber} type={numberField} name="nominalAmountOff" value={this.state.nominalAmountOff} onChange={this.handleInputChange} />
				</label>{
						this.state.errorPercentageOff && <p className={cssError } >{this.state.errorPercentageOff}</p>
					}</>
		}
	}

	steppedOfferDiscount(i, item) {
		switch (this.state.steppedOfferTiers[i].loyaltyOfferDiscount) {
			case rewardPercentage:
				return <><label className={cssFormInputLabel}>
					Percentage Off When Points Earned:
					<input className={cssFormInputNumber} type={numberField} name="percentageOff" value={item.percentageOff} onChange={this.handleSteppedOfferChange(i)} />
				</label>{this.state.steppedOfferTiers[i].errorPercentageOff && <p className={cssError} >{this.state.steppedOfferTiers[i].errorPercentageOff}</p>}</>
			case rewardNominal:
				return <><label className={cssFormInputLabel}>
					Discount Amount When Points Earned:
					<input className={cssFormInputNumber} type={numberField} name="nominalAmountOff" value={item.nominalAmountOff} onChange={this.handleSteppedOfferChange(i)} />
				</label>{
						this.state.steppedOfferTiers[i].errorPercentageOff && <p className={cssError} >{this.state.steppedOfferTiers[i].errorPercentageOff}</p>
					}</>
		}
	}

	loyaltyOfferSteppedReward(i) {
		return <label className={cssFormInputLabel}>Stepped Offer Reward:<Select
		className = { cssFormInputDropdown }
		placeholder = 'Select Tier Offer Reward'
		fluid
		selection
		search
		options = { loyaltyOfferRewards }
		name = "loyaltyOfferSteppedDiscount"
		onChange = { this.handleSteppedDropDownChange(i) }
			/></label>
	}

	render() {

		let posIntegration = false;
		let loyaltyOfferRulesDropdown = this.state.loading ? <p><em>Loading</em></p> : this.DisplayLoyaltyOfferRulesDropdown();

		let loyaltyOfferTarget = posIntegration ? <label className={cssFormInputLabel}>
			Loyalty Offer Target:
			<Select
				className={cssFormInputDropdown}
				placeholder='Select Loyalty Offer Target'
				fluid
				selection
				search
				options={loyaltyOfferTargets}
				name="loyaltyOfferTarget"
				onChange={this.handleDropDownChange}
			/>
			{this.state.errorLoyaltyOfferId && <p className={cssError}>{this.state.errorLoyaltyOfferId}</p>}
		</label> : "";
		let loyaltyOfferReward = posIntegration ? <label className={cssFormInputLabel}>
			Loyalty Offer Reward:
			<Select
				className={cssFormInputDropdown}
				placeholder='Select Loyalty Offer Reward'
				fluid
				selection
				search
				options={loyaltyOfferRewards}
				name="loyaltyOfferDiscount"
				onChange={this.handleDropDownChange}
			/>
		</label> : "";
		let loyaltyOfferDetails;

		let steppedOfferDetails;

		let loyaltyOfferRuleDetails;
		

		loyaltyOfferDetails = <><label className={cssFormInputLabel}>
			Number Of Points to Earn Reward:
			<input type={numberField} className={cssFormInputNumber} name="numberOfPoints" value={this.state.numberOfPoints} onChange={this.handleInputChange} />
		</label>{this.state.errorNumberOfPoints &&
			//display an error here
				<p className={cssError } >{this.state.errorNumberOfPoints}</p>}</>


		steppedOfferDetails = <>
			{this.state.steppedOfferTiers.map((item, i) => (
				<div key={i}> {posIntegration ? this.loyaltyOfferSteppedReward(i) : ""}
					<div>
						<label className={cssFormInputLabel}>Points to reach tier:<input className={cssFormInputNumber} name="numberOfStamps" type={numberField} step="1" value={item.numberOfStamps} onChange={this.handleSteppedOfferChange(i)} onFocus={this.handleFocusSelect} /></label>{item.errorNumberOfStamps && <p className={cssError}>{item.errorNumberOfStamps}</p>}
						<label className={cssFormInputLabel}>Tier Details:<input className={cssFormInputText} type="textarea" name="tierDetails" onChange={this.handleSteppedOfferChange(i)} /></label>{item.errorTierDetails && <p className={cssError}>{item.errorTierDetails}</p>}
					</div>
					{this.steppedOfferDiscount(i, item)}
					<label className={cssFormInputLabel}>Icon File:<input type={fileFieldType} name="icon" onChange={this.handleSteppedFileChange(i)} classNameName={cssFormInputFile} /></label>
					<label className={cssFormInputLabel}>Redeemed Icon File:<input type={fileFieldType} name="redeemedIcon" onChange={this.handleSteppedFileChange(i)} classNameName={cssFormInputFile} /></label>
					<div><button type="button" className={cssFormInputButton} onClick={this.handleRemoveSteppedOffer(i)}>Delete</button></div>
				</div>
			))}
			<button type="button" className={cssFormInputButton} onClick={this.handleAddSteppedOfferTier}>Add Stepped Offer Tier</button>
		</>

		if (this.state.loyaltyOfferRuleId == this.state.loyaltyOfferPeriodRewardId)
			loyaltyOfferRuleDetails = <><label className={cssFormInputLabel}>Period for reward to be available</label><br/>
				<label className={cssFormInputLabel}>Years:<input className={cssFormInputNumber} type={numberField} name="years" id="years" value={this.state.years} onChange={this.handleInputChange} /></label>
				<label className={cssFormInputLabel}>Months:<input className={cssFormInputNumber} type={numberField} name="months" id="months" value={this.state.months} onChange={this.handleInputChange} /></label>
				<label className={cssFormInputLabel}>Days:<input type={numberField} className={cssFormInputNumber} name="days" id="days" value={this.state.days} onChange={this.handleInputChange} /></label>{this.state.errorTimePeriod && <p className={cssError}>{this.state.errorTimePeriod}</p>}</>



		return (
			<LoyaltyOffersProvider>
				<NavMenu />
				<div><form onSubmit={this.handleSubmit} className={cssFormInput}>
					<label className={cssFormInputLabel}>
						Loyalty Offer Rules:
						{loyaltyOfferRulesDropdown}{this.state.errorLoyaltyOfferId && <p className={cssError}>{this.state.errorLoyaltyOfferId}</p>}
					</label>
				
					{loyaltyOfferTarget}
					{loyaltyOfferReward }
					

				<label className={cssFormInputLabel}>
					Loyalty Offer Details:
					<input className={cssFormInputText} type="textarea" name="offerDetails" id="offerDetails" value={this.state.offerDetails} onChange={this.handleInputChange} />
				</label>{this.state.errorSignUpAlertText && <p className={cssError}>{this.state.errorSignUpAlertText}</p>}
					{this.loyaltyOfferTargetDescription() }
					{loyaltyOfferDetails}{this.loyaltyOfferDiscount()}
					{loyaltyOfferRuleDetails}
					<label className={cssFormInputLabel}>
						Display Next Stepped Offer Description:
						<input type={checkBox} className={cssFormCheckBox} name="displayNextSteppedOfferDescription" value={this.state.displayNextSteppedOfferDescription} onChange={this.handleInputChange} />
					</label>
					{steppedOfferDetails }
					<input type="submit" className={cssFormInputButton} value="Save" disabled={this.state.submitButtonDisabled} />
				<img src={this.state.helpVisible ? HideHelp : ShowHelp} className="btn-help" onClick={this.toggleHelp}/>
			</form>
					
				<p id="help" className={"collapse" + (this.state.helpVisible ? ".in" : "") }>
					Add this rule - when a customer spends the spend amount for a point, they will have the point automatically added to their card. Once they have earned the number of points they will be able to click on their voucher and scan the till's QR Code to redeem their voucher - the discount will automatically be applied to their shopping bill.</p>
				</div>
				{this.state.loading ? <img src={DualBallSpinner} alt="Loading" /> : ''}
				<div id="response" />
			</LoyaltyOffersProvider>
		);
	}
}
